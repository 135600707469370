import React from 'react'
import Title from '../components/global/Title'
import ContactDetails from '../components/feature/ContactDetails'
import ContactForm from '../components/feature/ContactForm'

const Contact = () => {
  return (
    <div>
      <Title title='Contact Us' />
        <ContactDetails/>
        <ContactForm/>
    </div>
  )
}

export default Contact
