import { baseURL } from '../config';
import { callApi } from './api'


export const addLead = async (data) => {
    const {name, email, phone} = data;
    const apiData = {
        name,
        email,
        phone
    }

    const config = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded', 
        },
        url: `${baseURL}/api/v1/lead/`,
        data: apiData
    }

    const response = await callApi(config)
    return response;
}

export const addContact = async (data) => {
    const { name, email, comment} = data;

    const apiData = {
        name,
        email,
        comment
    }

    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${baseURL}/api/v1/contact/`,
        data: apiData
    }

    const response = await callApi(config);
    return response;
}

// module.exports = {
//     addContact,
//     addLead
// };
