const whatsappUrl = "https://wa.me/+918007362578";

export default whatsappUrl;
export const contactContent = {
    Vastushastra:
    "Contact Us: Start by reaching out to us to book your Vastu Shastra consultation. You can do so through our website, a phone call, or an email.<br/><br/>Preparation: To make the most of your consultation, be prepared by having specific questions and concerns in mind. You may also be asked to provide essential information such as floor plans, birth details, or any other relevant documents.<br/><br/>Booking Confirmation: After booking, you will receive a confirmation, and our team will review your request.<br/><br/>Payment: Ensure that you have cleared the necessary payment as agreed upon to confirm your consultation. Please note that the prices mentioned are applicable for sites within Pune. For sites outside Pune, charges will be different, as they may involve travel and accommodation expenses. Our team will provide you with the relevant pricing details for locations outside Pune.<br/><br/>Site Visit: Once the terms are settled and payment received, a skilled draftsman from our team will schedule a visit to your location to create an accurate floor plan of your property.<br/><br/>Consultation Process: Our expert, Vedika Mam, will use the accurate floor plan and calculations to guide you during the consultation. This guidance will be tailored to address your specific needs, goals, and concerns.<br/><br/>Response Time: Expect to be contacted by our team within 48 hours after booking your consultation. Please note that any holidays or consecutive holidays may cause a slight delay in email responses<br/><br/>Privacy and Confidentiality: Rest assured, we maintain strict privacy and confidentiality standards to protect your information.<br/><br/>We look forward to helping you with your Vastu Shastra consultation journey.",

    Numerology: "<p><strong>What Happens When You Request a Numerology Report</strong></p><p><strong>Gather Necessary Details:</strong> To create a personalized Numerology report, we require specific information. When you reach out to us, please be prepared to provide the following details:</p><ul><li>Your date of birth</li><li>Your full name</li><li>Company name (if applicable)</li><li>Any other relevant information</li></ul><p><strong>Numerology Report Creation:</strong> We will use the provided information to generate a personalized Numerology report for you. This report will offer insights and analysis based on Numerology principles.</p><p><strong>Scheduling a 30-Minute Zoom Session:</strong> After the Numerology report is ready, we will schedule a 30-minute Zoom video session with Vedika. During this session, you will have the opportunity to discuss the report, ask questions, and gain a deeper understanding of the insights provided.</p><p>We look forward to assisting you with your Numerology journey. Providing us with the necessary details is the first step towards gaining valuable insights into your life and potential.</p>",

    "Tarot Card Reading": "<p><strong>What Happens When You Book a Tarot Reading</strong></p><ul><li><strong>Duration:</strong> A typical Tarot reading session lasts for 30-45 minutes.</li><li><strong>Medium:</strong> The session is conducted through Zoom Video Calling, and we operate in the IST time zone.</li></ul><p><strong>Appointment Scheduling:</strong> If you book your Tarot reading from Monday to Friday, you can expect to receive an email from our team within the next 48 hours. This email will contain a link that allows you to schedule your appointment with Vedika.</p><p><strong>How to Prepare for Your Reading & Before Meeting Vedika:</strong></p><ol><li><strong>Define Your Goals:</strong> Before the reading, take some time to think about what you want to achieve from the session. Consider your goals and the specific insights or analysis you are seeking.</li><li><strong>Prepare Your Questions:</strong> To maximize the benefit of your session, compile a list of clear and well-defined questions. Vague questions may not yield the most helpful answers.</li><li><strong>Maintain an Open Mind:</strong> Approach the reading with an open mind. Avoid preconceived notions or expectations about the outcome. Understand that Tarot readings are a spiritual process, and not all answers may be immediately clear. Allow the understanding to evolve over time.</li><li><strong>Take Notes:</strong> During the session, take notes for future reference. Some responses may seem cryptic at the moment but could hold meaning in the future. Properly storing these notes can help you gain deeper insights.</li></ol><p>We look forward to assisting you on your Tarot reading journey with Vedika. Please keep these guidelines in mind to ensure a rewarding and insightful experience.</p>"
};
