import React from "react";
import { Link } from "react-router-dom";
import Title from "../components/global/Title";

const TermsOfService = () => {
  return (
    <div>
    <Title title='Terms of Service' />
      <div className="container">
    <center><h1>Terms of Service</h1></center>
      <br />
      <h3>1. Acceptance of Terms</h3>

      <p>
        By accessing or using The Alchemy Center’s website, you agree to comply
        with and be bound by these Terms of Service. If you do not agree, please
        refrain from using our services.
      </p>
      <h3>2. User Eligibility</h3>
      <p>
        Users must be at least 18 years old or have legal parental/guardian
        consent to use our services. By using the website, you affirm that you
        meet these eligibility criteria.
      </p>
      <h3>3. Account Creation and Security</h3>
      <p>
        To access certain features, users must create an account. You are
        responsible for maintaining the confidentiality of your account
        information and are liable for all activities under your account.
      </p>
      <h3>4. User Conduct</h3>
      <p>
        Users agree not to engage in any activity that violates these Terms of
        Service, including but not limited to unauthorized access, data mining,
        or any activity that could harm The Alchemy Center or its users.
      </p>
      <h3>5. Intellectual Property</h3>
      <p>
        All content and materials on the website, unless otherwise stated, are
        the intellectual property of The Alchemy Center. Users may not
        reproduce, distribute, or use any content without explicit permission.
      </p>
      <h3>6. Third-Party Links</h3>
      <p>
        The Alchemy Center is not responsible for the content or practices of
        third-party websites linked to our services. Users access external links
        at their own risk.
      </p>
      <h3>7. Termination of Services</h3>
      <p>
        The Alchemy Center reserves the right to terminate or suspend services,
        user accounts, or access to specific features without notice for
        violations of these Terms of Service.
      </p>
      <h3>8. Privacy Policy</h3>
      <p>
        User information is handled according to our Privacy Policy. By using
        our services, you agree to the terms outlined in the Privacy Policy.
      </p>
      <h3>9. Limitation of Liability</h3>
      <p>
        The Alchemy Center is not liable for any direct, indirect, incidental,
        consequential, or punitive damages arising from the use or inability to
        use our services.
      </p>
      <h3>10. Governing Law</h3>
      <p>
        These Terms of Service are governed by and construed in accordance with
        the laws of India. Any disputes arising from these terms
        will be subject to the exclusive jurisdiction of the courts in India.
      </p>
      <h3>11. Changes to Terms</h3>
      <p>
        The Alchemy Center reserves the right to modify or replace these Terms
        of Service at any time. Users are responsible for reviewing changes.
        Continued use of our services after changes constitutes acceptance.
      </p>
      <h3>12. Contact Information</h3>
      <p>
        For any questions or concerns regarding these Terms of Service, contact
        us at <Link to='email:alchemycenterbyvedika@gmail.com'>alchemycenterbyvedika@gmail.com</Link>.
      </p>
      </div>
    </div>
  );
};

export default TermsOfService;
