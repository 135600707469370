import React from "react";
import Title from "../components/global/Title";
import { Link } from "react-router-dom";

const PrivacyAndPolicies = () => {
  return (
    <div>
      <Title title="Privacy and Policies" />
      <div className="container">
        <h1>A. General</h1>
        <p>
          In this policy, the terms “we,” “our,” and “us” refer to The Alchemy
          Center, outlining the privacy mechanisms concerning personally
          identifiable or personal information, its collection, usage, and your
          choices.
        </p>

        <h1>B. Applicability</h1>
        <p>
          This privacy policy is for all Users accessing The Alchemy Center’s
          website. Users should read and understand the policy before submitting
          Personal Information. If you disagree with the information handling,
          you may request modification or deletion by contacting us at .
        </p>
        <h1>C. Access</h1>
        <p>
          Users can access the website as guests without creating an account,
          but to unlock all features, account creation is required. The Alchemy
          Center takes precautions regarding guest information but does not
          validate it unless required by law.
        </p>

        <h1>D. Personal Information</h1>
        <p>
          Personal Information includes user-identifying details provided during
          registration, such as name, email, age, phone number, and password.
        </p>

        <h1>E. Automatic Information Collection</h1>
        <p>
          We collect and analyze data like IP addresses, login details, browsing
          history, and more to enhance user experience and provide personalized
          services.
        </p>

        <h1>F. Information Security</h1>
        <p>
          We use SSL software for secure data transmission. Users must safeguard
          their password and device access to prevent unauthorized use.
        </p>

        <h1>G. Use of Your Personal Information</h1>
        <p>
          While availing services, you provide personal information, which we
          use for service delivery. You can request modification or deletion of
          incorrect information.
        </p>

        <h1>H. Exceptions</h1>
        <p>
          Personal Information may be shared with third parties under legal
          obligations or for fraud detection, security, or technical issues.
        </p>

        <h1>I. User Choices</h1>
        <p>
          Users can manage email preferences and opt-out of direct mailers. We
          respect privacy and address violations promptly.
        </p>

        <h1>J. Report, Analysis & Confidentiality</h1>
        <p>
          We may use Personal Information for research and analysis, ensuring
          confidentiality.
        </p>

        <h1>K. Sharing of Information</h1>
        <p>
          We may disclose or transfer User information during reorganization or
          asset sale.
        </p>

        <h1>L. Revision of Terms & Grievance Reporting</h1>
        <p>
          This policy may be updated without notice. Users can refuse amendments
          and withdraw Personal Information. For concerns or grievances, contact
          us at{" "}
          <Link to="email:alchemycenterbyvedika@gmail.com">
            {" "}
            alchemycenterbyvedika@gmail.com
          </Link>
        </p>
      </div>
    </div>
  );
};

export default PrivacyAndPolicies;
