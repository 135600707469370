import React from 'react'
import Title from '../components/global/Title'
import AboutJyotish from '../components/global/AboutJyotish'
import AboutFaqs from '../components/feature/AboutFaqs'
import Services from '../components/global/Services'

const About = () => {
  return (
    <div>
      <Title title='About Us' />
      <AboutJyotish/>
      <AboutFaqs/>
      <Services/>
    </div>
  )
}

export default About
