import React from 'react'
import { Link } from 'react-router-dom'
import data from '../../data/services.json'

const Footer = () => {
  return (
    <div>
       <div className="hs_footer_main_wrapper">

		<div className="hs_footer_top_wrapper">
			<div className="container">
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
						<div className="hs_footer_logo_wrapper">
							<img src="images/header/logo.png" alt="footer_logo" className="img-responsive" />
							<p>Welcome to Alchemy Center, where the mystical world of ancient practices meets modern guidance.</p>
							<h4><Link to="/about">Read More <i className="fa fa-long-arrow-right"></i></Link></h4>
							<ul>
								<li><a href="https://www.facebook.com/TheAlchemyCenter?mibextid=LQQJ4d"><i className="fa fa-facebook"></i></a></li>
								<li><a href="https://youtube.com/@vedikabakawat8908?si=ozjeRS5qj6d6KK40"><i className="fa fa-youtube-play"></i></a></li>
								<li><a href="https://www.instagram.com/alchemycenter_by_vedika/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"><i className="fa fa-instagram"></i></a></li>
							</ul>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
						<div className="hs_footer_list_wrapper">
							<h2><span>Links</span></h2>
							<ul className="hs_footer_list">
								<li><Link to="/"><i className="fa fa-caret-right" aria-hidden="true"></i>Home</Link></li>
								<li><Link to="/about"><i className="fa fa-caret-right" aria-hidden="true"></i>About Us </Link></li>
								<li><Link to="/services"><i className="fa fa-caret-right" aria-hidden="true"></i>Services </Link></li>
								<li><Link to="/contact"><i className="fa fa-caret-right" aria-hidden="true"></i>Contact Us</Link></li>
							</ul>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
						<div className="hs_footer_list_wrapper">
							<h2>Usefull <span>Links</span></h2>
							<ul className="hs_footer_list">
								{
									data.map(ele => {
										return (<li key={ele.name}>
											<Link to={`/service-single/${ele.name}`}><i className="fa fa-caret-right" aria-hidden="true"></i> {ele.name} </Link>
										</li>)
									})
								}
								
							</ul>
						</div>
					</div>
					{/* <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
						<div className="hs_download_wrapper">
							<h2>Download<span>App</span></h2>
							<p>Download Our Mobile App</p>
							<div className="hs_download_btn_wrapper">
							  <a href="/">
								<img src="images/content/App-Store.png" className="img-responsive" alt="App_Store_img"/>
							  </a>
							  <a href="/">
								<img src="images/content/Google-Play.png" className="img-responsive" alt="Google_Play_img"/>
							  </a>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</div>
    </div>
    <div className="hs_bottom_footer_main_wrapper">
        {/* <Link to="javascript:" id="return-to-top"><i className="fa fa-angle-up"></i></Link> */}
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                    <div className="footer_bottom_cont_wrapper">
                        <p>© Copyright 2020 by The Alchemy Center. All right Reserved - Design By <a href="https://www.hemendralalawat.com">Trolific</a></p>
                    </div>
                </div>
				<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                   <div className="footer_bottom_list_wrapper">
						<ul>
							<li><Link to="/privacypolicy">Privacy policy</Link>
							</li>
							<li><Link to="/termsofservice">Terms of Service</Link>
							</li>
							<li><Link to="/support">Support</Link>
							</li>
						</ul>
					</div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Footer
