import React from "react";
import { Link, useParams } from "react-router-dom";

const Payment = () => {
  const { status } = useParams();

  

  return (
    <div className="container login-wrapper">
      <div
        id="loginbox"
        style={{ marginTop: "50px" }}
        className="mainbox col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2"
      >
        <div className="panel panel-info custom-panel-heading">
          <div className="panel-heading  custom-panel-title">
            <div className="panel-title">Payment Status</div>
            <div
              style={{
                float: "right",
                fontSize: "80%",
                position: "relative",
                top: "-25px",
              }}
            >
              <Link className="mx" to="/">
                Home
              </Link>
            </div>
          </div>

          <div style={{ paddingTop: "30px" }} className="panel-body">
            <div
              style={{ display: "none" }}
              id="login-alert"
              className="alert alert-danger col-sm-12"
            ></div>

            <form id="loginform" className="form-horizontal">
              <center>
                {" "}
                <h1>
                  {" "}
                  Payment {status === "success" ? "Successfull" : "Failure"}
                </h1>
                {status === "success" ? (
                  <p>Transaction Id: 'asdfasdfasdf'</p>
                ) : (
                  <p>Something went wrong with payment. Please try again.</p>
                )}
              </center>

              {/* <div className="form-group">
                <div className="col-md-12 control">
                <div
                    style={{
                      borderTop: "1px solid #888",
                      paddingTop: "15px",
                      fontSize: "85%",
                    }}
                  >
                    Thank you for using Alchemy Center{" "}
                    <button className="btn btn-link" onClick={() => {}}>
                      Close
                    </button>
                  </div>
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
