import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div>
      <div className="main_header_wrapper">
        <div className="hs_navigation_header_wrapper">
          <div className="">
            <div className="row">
              <div className="col-lg-6 col-md-5 col-sm-5 col-xs-5">
                <div className="hs_header_add_wrapper border_icon hidden-sm hidden-xs">
                  <div className="hs_header_add_icon">
                    <Link to="/" style={{ color: "white" }}>
                      <i className="fa fa-home"></i>
                    </Link>
                  </div>
                  <div className="hs_header_add_icon mx">
                    {/* <Link
                      data-toggle="modal"
                      data-target="#myModal"
                      style={{ color: "white", marginLeft: "15px" }}
                    >
                      <i className="fa fa-whatsapp"></i>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hs_header_Wrapper hidden-sm hidden-xs ">
          <div className="hs_top_header_main_Wrapper flex-between relative">
            <div className="hs_header_logo_left">
              <nav className="hs_main_menu">
                <ul>
                  <li className="dropdown menu-button">
                    <Link className="menu-button" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link className="menu-button" to="/about">
                      About Us
                    </Link>
                  </li>
                  <li className="dropdown menu-button">
                    <Link className="menu-button" to="/services">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link className="menu-button" to="/contact">
                      Contact
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="menu-button" to="/login">
                      Login
                    </Link>
                  </li> */}
                </ul>
              </nav>
            </div>

            <div className="hs_logo_wrapper custom-logo-wrapper">
              <Link to="/">
                <img
                  src="images/header/logo.png"
                  className="img-responsive"
                  alt="logo"
                  title="Logo"
                />
              </Link>
            </div>
            <div className="hs_btn_wrapper hidden-md">
              <ul>
                <li>
                  <button
                    data-toggle="modal"
                    data-target="#myModal"
                    className="hs_btn_hover"
                    style={{ color: "white", padding: "15px 25px", border: 'none' }}
                  >
                    Appointments
                  </button>
                </li>
              </ul>
            </div>

            {/* <div className="hs_header_logo_right">
              
            </div> */}
          </div>
        </div>
        <header id="0" className="mobail_menu visible-sm visible-xs">
          <div className="container">
            <div className="row">
              <div className="col-xs-6 col-sm-6">
                <div className="hs_logo">
                  <Link to="/">
                    <img
                      className="logo-sm"
                      src="/images/header/logo.png"
                      alt="Logo"
                      title="Logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-xs-6 col-sm-6">
                <div className="cd-dropdown-wrapper">
                  <a className="house_toggle" href="#0">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="511.63px"
                      height="511.631px"
                      viewBox="0 0 511.63 511.631"
                      style={{ enableBackground: "new 0 0 511.63 511.631" }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M493.356,274.088H18.274c-4.952,0-9.233,1.811-12.851,5.428C1.809,283.129,0,287.417,0,292.362v36.545
              c0,4.948,1.809,9.236,5.424,12.847c3.621,3.617,7.904,5.432,12.851,5.432h475.082c4.944,0,9.232-1.814,12.85-5.432
              c3.614-3.61,5.425-7.898,5.425-12.847v-36.545c0-4.945-1.811-9.233-5.425-12.847C502.588,275.895,498.3,274.088,493.356,274.088z"
                          />
                          <path
                            d="M493.356,383.721H18.274c-4.952,0-9.233,1.81-12.851,5.427C1.809,392.762,0,397.046,0,401.994v36.546
              c0,4.948,1.809,9.232,5.424,12.854c3.621,3.61,7.904,5.421,12.851,5.421h475.082c4.944,0,9.232-1.811,12.85-5.421
              c3.614-3.621,5.425-7.905,5.425-12.854v-36.546c0-4.948-1.811-9.232-5.425-12.847C502.588,385.53,498.3,383.721,493.356,383.721z"
                          />
                          <path
                            d="M506.206,60.241c-3.617-3.612-7.905-5.424-12.85-5.424H18.274c-4.952,0-9.233,1.812-12.851,5.424
              C1.809,63.858,0,68.143,0,73.091v36.547c0,4.948,1.809,9.229,5.424,12.847c3.621,3.616,7.904,5.424,12.851,5.424h475.082
              c4.944,0,9.232-1.809,12.85-5.424c3.614-3.617,5.425-7.898,5.425-12.847V73.091C511.63,68.143,509.82,63.861,506.206,60.241z"
                          />
                          <path
                            d="M493.356,164.456H18.274c-4.952,0-9.233,1.807-12.851,5.424C1.809,173.495,0,177.778,0,182.727v36.547
              c0,4.947,1.809,9.233,5.424,12.845c3.621,3.617,7.904,5.429,12.851,5.429h475.082c4.944,0,9.232-1.812,12.85-5.429
              c3.614-3.612,5.425-7.898,5.425-12.845v-36.547c0-4.952-1.811-9.231-5.425-12.847C502.588,166.263,498.3,164.456,493.356,164.456z"
                          />
                        </g>
                      </g>
                    </svg>
                  </a>
                  <nav className="cd-dropdown">
                    <h2>{/* <a href="index.html">Jyotish</a> */}</h2>
                    <a href="#0" className="cd-close">
                      Close
                    </a>
                    <ul className="cd-dropdown-content">
                      <li>
                        <Link href="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About US</Link>
                      </li>
                      <li>
                        <Link to="/services">Services</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                      {/* <li>
                        <Link className="menu-button" to="/login">
                          Login
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          data-toggle="modal"
                          data-target="#myModal"
                          className="hs_btn_hover"
                        >
                          Appointments
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Navbar;
