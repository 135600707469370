import React, { useState } from 'react'

const AboutJyotish = () => {

   const [readMore, setReadMore] = useState('none');

   const handleClick = (e) => {
    e.preventDefault();
    if(readMore === 'none'){
        setReadMore('block');
    }else {
        setReadMore('none');
    }
  }

  return (
    <div>
      <div className="hs_about_indx_main_wrapper">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="hs_about_left_img_wrapper">
                        <img src="images/content/about/vbakawat.jpg" alt="about_img" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
					<div className="hs_about_heading_main_wrapper">
                        <div className="hs_about_heading_wrapper">
                            <h2>About <span>Us</span></h2>
                            <h4><span>&nbsp;</span></h4>
                        </div>
                    </div>
                    <div className="hs_about_right_cont_wrapper">
                        <p>Welcome to Alchemy Center, where the mystical world of ancient practices meets modern guidance. I'm Vedika Bakawat, the founder of Alchemy Center, and I'd like to share my journey with you.
From a young age, I was drawn into the enigmatic realm of spirituality and healing. Growing up in a family where astrology, yoga, tantra, and mysticism were everyday conversations, I was exposed to the spiritual world from the very beginning. My grandfather was an astrologer, my uncle a practitioner of yoga and tantra, and my intuition seemed to guide me naturally toward these mystical arts.
As fate would have it, I pursued a degree in Computer Science and became a data scientist—a path that was deeply rooted in the scientific world. Little did I know that my life was about to take a profound turn.
The truth is, I didn't choose this career; it chose me. My journey into Vastushastra, Tarot Card Reading, Numerology, Crystal Healing, and Tera-Mai (USA) Reiki & Seichem Healing began as a personal exploration. I discovered that these ancient practices held an innate power to heal, guide, and transform lives.
Over the years, I've dedicated myself to mastering these arts, becoming a certified Vastu consultant, Tarot card reader, numerologist, crystal healer, and Tera-Mai (USA) Reiki & Seichem healer. </p>

<p style={{display:readMore}}>Alchemy Center was born in 2016, and since then, it has been my privilege to serve a global client base, helping individuals discover their paths, overcome challenges, and embrace their true selves.
I firmly believe that sometimes, our calling finds us, guiding us toward a purpose we may not have initially chosen. It's a journey of intuition, spirituality, and self-discovery, and I'm here to share it with you.
Whether you're seeking Vastushastra guidance, Tarot card insights, Numerology revelations, Crystal Healing, or the transformative power of Tera-Mai (USA) Reiki & Seichem Healing, Alchemy Center is your destination for holistic well-being and spiritual growth.
Join me on this remarkable journey. Together, we'll uncover the hidden wisdom of the universe and navigate the intricacies of life's beautiful tapestry.
Thank you for choosing Alchemy Center. I look forward to being a part of your journey. Warm regards, Vedika Bakawat Founder, Alchemy Center</p> <br /> <button onClick={handleClick} className='btn btn-primary-color  mt-5'  > { readMore === 'none'? 'Read More' : 'Read Less'}</button>
                       
                        <div className="hs_effect_btn hs_about_btn">
                            {/* <ul>
                                <li><a href="/" className="hs_btn_hover">Read more</a></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default AboutJyotish
