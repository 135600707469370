import { Link } from "react-router-dom";

const ForgotPassword = () => {
  return (
    <div>
      <div className="container login-wrapper">
        <div
          id="loginbox"
          style={{ marginTop: "50px" }}
          className="mainbox col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2"
        >
          <div className="panel panel-info custom-panel-heading">
          <div className="panel-heading  custom-panel-title">
              <div className="panel-title">Forgot Password? </div>
              <div
                style={{
                  float: "right",
                  fontSize: "80%",
                  position: "relative",
                  top: "-25px",
                }}
              >
                <div>
                <Link to="/" className="mx">Login</Link>
                <Link className='mx' to="/">Home</Link>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: "30px" }} className="panel-body">
              <div
                style={{ display: "none" }}
                id="login-alert"
                className="alert alert-danger col-sm-12"
              ></div>

              <form id="loginform" className="form-horizontal">
                <div style={{ marginBottom: "25px" }} className="input-group">
                  <span className="input-group-addon">
                    <i className="glyphicon glyphicon-user"></i>
                  </span>
                  <input
                    id="login-username"
                    type="text"
                    className="form-control"
                    name="email"
                    value=""
                    placeholder="email"
                  />
                </div>

                <div style={{ marginTop: "10px" }} className="form-group">
                  <div className="col-sm-12 controls">
                    <Link id="btn-login" to="/verify-otp" className="btn btn-primary-color">
                      Send Verification Code
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
