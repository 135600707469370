import React from "react";
// import whatsappUrl from '../../constants/constants'

const FloatingButton = () => {
  return (
    <div className="floating-whatsapp">
      <button data-toggle="modal" data-target="#myModal">
        <img src="images/icon/whatsapp.png" alt="" />
      </button>
    </div>
  );
};

export default FloatingButton;
