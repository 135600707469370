import React, { useEffect } from "react";
import { useState } from "react";
import whatsappUrl, {contactContent} from "../constants/constants";
import { addLead } from "../api/callAPI";
import { useParams } from "react-router-dom";
import Title from "../components/global/Title";
import PlainTextToHtml from "../components/feature/PlainTextToHtml";

const BookNowPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [data, setData] = useState('')

  const {id} = useParams();



  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const response = await addLead({ name, email, phone });

    if (response.status) {
      return window.location.replace(whatsappUrl);
    } else {
      console.error("Error ------> ", response);
    }
  };

  useEffect(() => {
    if(id) {
        setData(contactContent[id])
    }
  }, [id])

  return (
    <div className="" style={{ height: "100%" }}>
      <Title title={"Contact Us At"} />
      <div className="modal-body">
        <div className="display-flex">
          <div className="width-50" style={{ margin: "3rem", padding: "3rem" }}>
            <PlainTextToHtml text={data} />
          </div>
          <form
            className="width-50 m-5 p-5"
            style={{ margin: "3rem", padding: "3rem" }}
          >
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Enter your name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number:</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                placeholder="Enter your phone number"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter your email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary-color"
              onClick={async (e) => await onSubmitHandler(e)}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookNowPage;
