import React from 'react'
import { Link } from 'react-router-dom'

const Title = ({title}) => {
  return (
    <div>
      <div className="hs_indx_title_main_wrapper">
        <div className="hs_title_img_overlay"></div>
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 full_width">
                    <div className="hs_indx_title_left_wrapper">
                        <h2>{title}</h2>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  full_width">
                    <div className="hs_indx_title_right_wrapper">
                        <ul>
                            <li><Link to="/">Home</Link> &nbsp;&nbsp;&nbsp; <Link/> </li>
                            <li>{title}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Title
