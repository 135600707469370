import React from 'react'
import { Link } from 'react-router-dom'

const GenerateButton = ({destination}) => {
  return (
    <center>
      <button className={'custom-button margin-top hover-button '}>
        <Link to={`/generate/${destination}`} className="text-white">
          {" "}
          Book Now
        </Link>
      </button>
    </center>
  )
}

export default GenerateButton
