import React from "react";
import data from "../../data/services.json";
import { Link } from "react-router-dom";
import BookNowButton from "../feature/BookNowButton";
import Title from "./Title";
import GenerateButton from "../feature/GenerateButton";

const Services = ({home}) => {
  return (
    <div>
      {!home ? <Title title='Services' /> : ''}
      <div className="hs_service_main_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="hs_about_heading_main_wrapper">
                <div className="hs_about_heading_wrapper">
                  <h2>
                    Our <span> services</span>
                  </h2>
                  <h4>
                    <span>&nbsp;</span>
                  </h4>
                  {/* <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum<br/> auctor, nisi elit consequat hello Aenean world.</p> */}
                </div>
              </div>
            </div>
            <div className="portfolio-area ptb-100">
              <div className="container">
                <div className="row">
                  {data.map((ele) => {
                    return (
                      <div key={ele.name} className="col-xs-12 col-sm-6 col-md-4 col-lg-4 relative ">
                        <div className="hs_service_main_box_wrapper pb-4">
                          <div className="hs_service_icon_wrapper">
                            <i
                              className="fa fa-caret-square-o-up"
                              aria-hidden="true"
                            ></i>
                            <div className="btc_step_overlay"></div>
                          </div>
                          <div className="hs_service_icon_cont_wrapper ">
                            <h2>{ele.name}</h2>
                            <p>{ele.description.slice(0, 50)}....</p>
                            <h4 style={{color:"black"}} >{ele.price}</h4>
                            <h5>
                              <Link to={"/service-single/" + ele.name}>
                                Read More{" "}
                                <i className="fa fa-long-arrow-right"></i>
                              </Link>
                            </h5>
                            <GenerateButton destination={ele.name} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
