import React from 'react';

function PlainTextToHtml({ text }) {
  // Sanitize the text if needed
  const sanitizedText = sanitizeText(text);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: sanitizedText,
      }}
    />
  );
}

function sanitizeText(text) {
  return text;
}

export default PlainTextToHtml;