import React, { useState } from "react";
import { Link } from "react-router-dom";

const Login = () => {
  const [isLogin, setIsLogin] = useState(true);

  const [registerEmail, setRegisterEmail] = useState('');
  const [firstName, setFirstname] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');


  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  const handleOnRegister = async () => {
    console.log('register call')
  }

  return (
    <div className="container login-wrapper">
      <div
        id="loginbox"
        style={{ marginTop: "50px" }}
        className="mainbox col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2"
      >
        <div className="panel panel-info custom-panel-heading">
          <div className="panel-heading  custom-panel-title">
            <div className="panel-title">Sign {isLogin ? "In" : "Up"}</div>
            <div
              style={{
                float: "right",
                fontSize: "80%",
                position: "relative",
                top: "-25px",
              }}
            >
              {isLogin ? (
                <Link to="/forgotpassword">Forgot password?</Link>
              ) : (
                <button
                  id="signinlink"
                  className="btn btn-link"
                  onClick={toggleForm}
                >
                  Sign In
                </button>
              )}

              <Link className="mx" to="/">
                Home
              </Link>
            </div>
          </div>

          <div style={{ paddingTop: "30px" }} className="panel-body">
            <div
              style={{ display: "none" }}
              id="login-alert"
              className="alert alert-danger col-sm-12"
            ></div>

            {isLogin ? (
              <form id="loginform" className="form-horizontal">
                <div style={{ marginBottom: "25px" }} className="input-group">
                  <span className="input-group-addon">
                    <i className="glyphicon glyphicon-user"></i>
                  </span>
                  <input
                    id="login-username"
                    type="text"
                    className="form-control"
                    name="username"
                    value=""
                    placeholder="email"
                  />
                </div>

                <div style={{ marginBottom: "25px" }} className="input-group">
                  <span className="input-group-addon">
                    <i className="glyphicon glyphicon-lock"></i>
                  </span>
                  <input
                    id="login-password"
                    type="password"
                    className="form-control"
                    name="password"
                    placeholder="password"
                  />
                </div>

                <div className="input-group">
                  <div className="checkbox">
                    <label>
                      <input
                        id="login-remember"
                        type="checkbox"
                        name="remember"
                        value="1"
                      />{" "}
                      Remember me
                    </label>
                  </div>
                </div>

                <div style={{ marginTop: "10px" }} className="form-group">
                  <div className="col-sm-12 controls">
                    <a id="btn-login" href="/" className="btn btn-primary-color">
                      Login
                    </a>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-12 control">
                    <div
                      style={{
                        borderTop: "1px solid #888",
                        paddingTop: "15px",
                        fontSize: "85%",
                      }}
                    >
                      Don't have an account!{" "}
                      <button className="btn btn-link" onClick={toggleForm}>
                        Sign Up Here
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              // Sign Up Form
              <form id="signupform" className="form-horizontal">
                <div
                  id="signupalert"
                  style={{ display: "none" }}
                  className="alert alert-danger"
                >
                  <p>Error:</p>
                  <span></span>
                </div>

                <div className="form-group">
                  <label for="email" className="col-md-3 control-label">
                    Email
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="Email Address"
                      required
                      value={registerEmail}
                      onChange={(e) => setRegisterEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label for="firstname" className="col-md-3 control-label">
                    First Name
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      name="firstname"
                      placeholder="First Name"
                      required
                      value={firstName}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label for="lastname" className="col-md-3 control-label">
                    Last Name
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      name="lastname"
                      placeholder="Last Name"
                      required
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label for="password" className="col-md-3 control-label">
                    Password
                  </label>
                  <div className="col-md-9">
                    <input
                      type="password"
                      className="form-control"
                      name="passwd"
                      placeholder="Password"
                      required
                      value={registerPassword}
                      onChange={(e) => setRegisterPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label for="icode" className="col-md-3 control-label">
                    Phone Number
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      name="icode"
                      placeholder="Phone Number"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-offset-3 col-md-9">
                    <button
                      id="btn-signup"
                      type="button"
                      className="btn btn-info"
                      onClick={() => handleOnRegister()}
                    >
                      <i className="icon-hand-right"></i> &nbsp; Sign Up
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
