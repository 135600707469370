import React from "react";
import faqsData from "../../data/faqs.json";

const AboutFaqs = () => {
  return (
    <div>
      <div className="hs_about_progress_main_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="hs_about_progress_cont_left">
                <h2>Questions (faq’s)</h2>
                <h4>
                  <span>&nbsp;</span>
                </h4>
                <div className="accordionFifteen">
                  <div
                    className="panel-group"
                    id="accordionFifteenLeft"
                    role="tablist"
                  >
                    {faqsData.map((ele, index) => (
                      <div ele={index} className="panel panel-default truck_pannel">
                        <div className="panel-heading desktop">
                          <h4 className="panel-title">
                            <a
                              data-toggle="collapse"
                              data-parent="#accordionFifteenLeft"
                              href={`#collapseFifteenLeft${index+1}`}
                              ariaExpanded={index === 0 ? "true" : "false"}
                            >
                              {" "}
                              {ele.question}
                            </a>
                          </h4>
                        </div>
                        <div
                          id={`collapseFifteenLeft${index+1}`}
                          className="panel-collapse collapse in"
                          ariaExpanded={index === 0 ? "true" : "false"}
                          role="tabpanel"
                        >
                          <div className="panel-body">
                            <div className="panel_cont">
                              <p>
                                {ele.answer}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFaqs;
