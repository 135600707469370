import React from "react";
import Title from "../components/global/Title";

const Support = () => {
  return (
    <div>
      <Title title="Support" />
      <div className="container">
        <h1>Contact Information:</h1>
        <p>
          For general inquiries and assistance, you can reach our support team
          through the following channels:
        </p>
        <p>• Email: alchemycenterbyvedika@gmail.com</p>
        <h3>Operating Hours:</h3>
        <p>
          Our support team is available during the following hours
          Monday-Saturday 10am -6pm IST. Please note that responses may take up
          to 24-48 hours.
        </p>
      </div>
    </div>
  );
};

export default Support;
