import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
// import './styles.css';

// import required modules
import { Autoplay, Navigation } from "swiper/modules";


import testimonials from "../../data/testimonials.json";

const Testimonials = () => {
  const [testimonialData, setTestimonialData] = useState([]);

  useEffect(() => setTestimonialData(testimonials), []);

  return (
    <div>
      {testimonialData.length && (
        <div className="hs_testi_slider_main_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="hs_about_heading_main_wrapper">
                  <div className="hs_about_heading_wrapper">
                    <h2>
                      What clients <span> are saying</span>
                    </h2>
                    <h4>
                      <span>&nbsp;</span>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="hs_testi_slider_wrapper">
                  <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={30}
                    navigation={true}
                    modules={[Navigation, Autoplay]}
                    className="mySwiper"
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                  >
                    {testimonials.map((ele) => (
                      <SwiperSlide key={ele.name}>
                        <div className="d-flex flex-column gap-5  justify-content-center align-items-center text-center z-10 bg-white" >
                          <div className="my" >{ele.name}</div>
                          <div className="my" >{ele.source}</div>
                          <div className="my">{ele.description}</div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Testimonials;
