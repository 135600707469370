import React, { useState } from "react";
import { addContact } from "../../api/callAPI";
import whatsappUrl from "../../constants/constants";

const ContactForm = () => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [comment, setComment] = useState(null);

	const handleSubmitForm = async (e) => {
		e.preventDefault();

		const response = await addContact({name, email, comment})

		if(response.status){
			return window.location.replace(whatsappUrl);
		}else {
			console.error('Response ======> ', response)
		}

	}

  return (
    <div>
      <div className="hs_contact_indx_form_main_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="hs_about_heading_main_wrapper">
                <div className="hs_about_heading_wrapper">
                  <h2>
                    Feel Free To <span>Contact</span>
                  </h2>
                  <h4>
                    <span>&nbsp;</span>
                  </h4>
                  {/* <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum<br/> auctor, nisi elit consequat hello Aenean world.</p> */}
                </div>
              </div>
            </div>
            <form onSubmit={(e) => handleSubmitForm(e)} >
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="hs_kd_six_sec_input_wrapper i-name">
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    name="first_name"
                    className="require"
                    placeholder="Name"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="hs_kd_six_sec_input_wrapper i-email">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    name="email"
                    className="require"
                    placeholder="Email"
                    data-valid="email"
                    data-error="Email should be valid."
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="hs_kd_six_sec_input_wrapper i-message">
                  <textarea
                    rows="6"
                    name="message"
					value={comment}
					onChange={e => setComment(e.target.value)}
                    className="require"
                    placeholder="Comments"
                  ></textarea>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="response"></div>
                <div className="hs_kd_six_sec_btn">
                  <ul>
                    <li>
                      <button type="submit" onSubmit={(e) => handleSubmitForm(e)} className="hs_btn_hover submitForm">
                        Send a Message
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
