import React from 'react'

const ContactDetails = () => {
  return (
    <div>
            <div className="hs_contact_tittle_main_wrapper">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="hs_about_heading_main_wrapper">
                        <div className="hs_about_heading_wrapper">
                            <h2>Get in <span>Touch</span></h2>
                            <h4><span>&nbsp;</span></h4>
                        </div>
                    </div>
                </div>
                
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-block w-50">
                    <div className="hs_contact_title_box_wrapper">
                        <i className="fa fa-envelope"></i>
                        <div className="inner_wrapper">
                            <h4><a href="/">Email</a></h4>
							<p>
                                alchemycenterbyvedika@gmail.com
							</p>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default ContactDetails
