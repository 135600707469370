import React from 'react'
import Slider from '../components/feature/Slider'
import Services from '../components/global/Services'
import AboutJyotish from '../components/global/AboutJyotish'
import Testimonials from '../components/global/Testimonials'
import Instagram from '../components/feature/Instagram'


const Home = () => {
  return (
    <div>
        <Slider/>
        <Services home={true}/>
        <AboutJyotish/>
        <Testimonials/>
        <Instagram/>
    </div>
  )
}

export default Home
