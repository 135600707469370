import { Link } from "react-router-dom";

const VerifyOtp = () => {
    return (
        <div>
      <div className="container login-wrapper">
        <div
          id="loginbox"
          style={{ marginTop: "50px" }}
          className="mainbox col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2"
        >
         <div className="panel panel-info custom-panel-heading">
          <div className="panel-heading  custom-panel-title">
              <div className="panel-title">Enter OTP</div>
              <div
                style={{
                  float: "right",
                  fontSize: "80%",
                  position: "relative",
                  top: "-25px",
                }}
              >
                <Link to="/login" className="mx">Login</Link>
                <Link className='mx' to="/">Home</Link>
              </div>
            </div>

            <div style={{ paddingTop: "30px" }} className="panel-body">
              <div
                style={{ display: "none" }}
                id="login-alert"
                className="alert alert-danger col-sm-12"
              ></div>

              <form id="loginform" className="form-horizontal">
                <div style={{ marginBottom: "25px" }} className="input-group">
                  <span className="input-group-addon">
                    <i className="glyphicon glyphicon-lock"></i>
                  </span>
                  <input
                    id="login-username"
                    type="text"
                    className="form-control"
                    name="otp"
                    value=""
                    placeholder="Enter OTP"
                  />
                </div>

                <div style={{ marginTop: "10px" }} className="form-group">
                  <div className="col-sm-12 controls">
                    <a id="btn-login" href="/" className="btn btn-primary-color">
                      Verify
                    </a>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default VerifyOtp;