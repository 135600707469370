import React, { useEffect, useState } from "react";
import serviceData from "../data/services.json";
import { useParams } from "react-router-dom";

import Title from "../components/global/Title";
import BookNowButton from "../components/feature/BookNowButton";
import GenerateButton from "../components/feature/GenerateButton";

const SingleService = () => {
  const [data, setData] = useState([]);
  const params = useParams();
  const [bookNowLink, setBookNowLink] = useState('')

  function dataSet() {
    let newData = serviceData.filter((ele) => ele.name === params.name);

    setData(newData[0]);
  }

  useEffect(() => {
    dataSet();
    if(params.name === 'Vastushastra'){
      setBookNowLink('vastu')
    }else if(params.name === 'Numerology') {

    }
  }, [params.name]);

  return (
    <div>
      <Title title={data.name} />
      <br />
      <br />
      <br />
      <br />
      <div className="hs_kd_sidebar_main_wrapper hs_num_sidebar_main_wrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-12 col-md-9 col-sm-12 col-xs-12">
              <div className="hs_kd_left_sidebar_main_wrapper">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="hs_sign_left_tabs_wrapper hs_sign_left_tabs_border_wrapper1">
                      <div className="hs_slider_tabs_icon_wrapper">
                        {/* <i className="flaticon-aries-sign"></i> */}
                      </div>
                      <div className="hs_slider_tabs_icon_cont_wrapper hs_ar_tabs_heading_wrapper">
                        <ul>
                          <li>
                            <a href="#" className="hs_tabs_btn">
                              {data.name}
                            </a>
                          </li>
                          {/* <li>31 March - 12 October</li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="hs_ar_first_sec_wrapper">
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <div className="hs_ar_first_sec_img_wrapper">
                            <img src={data.image} />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <div className="hs_ar_first_sec_img_cont_wrapper">
                            <p>{data.description}</p>
                          </div>
                        </div>
                        {data && data.content ? (
                          data.content.map((ele, index) => {
                            return (
                              <div key={index} className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="hs_ar_second_sec_cont_wrapper">
                                  <strong style={{ color: "black" }}>
                                    {ele.question}
                                  </strong>
                                  <p>
                                    {ele.answer
                                      .split("<br/>")
                                      .map((element) => {
                                        return <p>{element}</p>;
                                      })}
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <center className="my" style={{ color: "black", marginTop: "50px" }} >
          <strong >Price</strong>
          <p>{data.price}</p>
        </center>
        <GenerateButton destination={params.name} />
      </div>
    </div>
  );
};

export default SingleService;
