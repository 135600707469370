import React from 'react'

const Instagram = () => {
  return (
    <div className='container'>
      
    </div>
  )
}

export default Instagram
