import Footer from "./components/global/Footer.jsx";
import Navbar from "./components/global/Navbar.jsx";
import Services from "./components/global/Services.jsx";
import About from "./pages/About.jsx";
import Contact from "./pages/Contact.jsx";
import Home from "./pages/Home.jsx";
import Login from "./pages/Sign.jsx";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import SingleService from "./pages/SingleService.jsx";
import ForgotPassword from "./pages/ForgotPassword.jsx";
import VerifyOtp from "./pages/VerifyOtp.jsx";
import FloatingButton from "./components/global/FloatingButton.jsx";
import TermsOfService from "./pages/TermsOfService.jsx";
import Support from "./pages/Support.jsx";
import PrivacyAndPolicies from "./pages/PrivacyAndPolicies.jsx";
import Modal from "./components/feature/Modal.jsx";
import Payment from "./pages/Payment.jsx";
import { useEffect } from "react";
import useScript from "./hooks/useScript.js";
import BookNowPage from "./pages/BookNowPage.jsx";

const scripts = [
  "/js/jquery_min.js",
  "/js/bootstrap.min.js",
  "/js/modernizr.js",
  "/js/jquery.menu-aim.js",
  "/js/parallax.min.js",
  "/js/owl.carousel.js",
  "/js/jquery.shuffle.min.js",
  "/js/jquery.countTo.js",
  "/js/jquery.inview.min.js",
  "/js/jquery.magnific-popup.js",
  "/js/datepicker.js",
  "/js/custom.js",
  "//www.instagram.com/embed.js",
]


function App() {
  const router = createBrowserRouter(
    createRoutesFromElements([
      <Route path="/" element={<Root />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/service-single/:name" element={<SingleService />} />
        <Route path="/login" element={<Login />} />,
        <Route path="/forgotpassword" element={<ForgotPassword />} />,
        <Route path="/verify-otp" element={<VerifyOtp />} />,
        <Route path="/termsofservice" element={<TermsOfService />} />,
        <Route path="/privacypolicy" element={<PrivacyAndPolicies />} />,
        <Route path="/support" element={<Support />} />,
        <Route path="/payment/:status" element= {<Payment/>}/>
        <Route path='/generate/:id' element={<BookNowPage/>}/>
        <Route path='/generate/' element={<BookNowPage/>}/>
      </Route>,
    ])
  );

  useScript('/js/jquery_min.js', 'html', false)
  useScript('/js/bootstrap.min.js', 'html', false)
  useScript('/js/modernizr.js', 'html', false)
  useScript('/js/jquery.menu-aim.js', 'html', false)
  useScript('/js/parallax.min.js', 'html', false)
  useScript('/js/owl.carousel.js', 'html', false)
  useScript('/js/jquery.shuffle.min.js', 'html', false)
  useScript('/js/jquery.countTo.js', 'html', false)
  useScript('/js/jquery.inview.min.js', 'html', false)
  useScript('/js/jquery.magnific-popup.js', 'html', false)
  useScript('/js/datepicker.js', 'html', false)
  useScript('/js/custom.js', 'html', false)
  useScript('//www.instagram.com/embed.js', 'html', false)

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

const Root = () => {
  return (
    <div className="relative">
      <Modal/>
      <Navbar />
      <Outlet />
      <FloatingButton/>
      <Footer />
    </div>
  );
};

export default App;
