import React from 'react'
import { Link } from 'react-router-dom'

const Slider = () => {
  return (
    <div>
      <div className="slider-area">
        <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner" role="listbox">
                <div className="item active">
                    <div className="carousel-captions caption-1">
                        <div className="container">
                            <div className="row">
                                 <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="content">
                                        <h1 data-animation="animated bounceInLeft">Welcome To</h1>
                                        <h2 data-animation="animated zoomInDown">the <span>Alchemy</span> Center</h2>
                                        <p data-animation="animated bounceInUp">Welcome to Alchemy Center, where the mystical world of ancient practices meets modern guidance.<br/> I'm Vedika Bakawat, the founder of Alchemy Center, and I'd like to share my journey with you.</p>
                                        <div className="hs_effect_btn">
                                            <ul>
                                                <li data-animation="animated flipInX"><Link to="/about" className="hs_btn_hover">Read more</Link></li>
                                            </ul>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 hidden-xs">
                                    <div className="content_tabs">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="hs_slider_right_tabs_wrapper hs_slider_right_tabs_wrapper2" data-animation="animated bounceInRight hs_slider_tab_tow">
                                                    <div className="hs_slider_tabs_icon_wrapper">
                                                        <i className="flaticon-home"></i>
                                                    </div>
                                                    <div className="hs_slider_tabs_icon_cont_wrapper">
                                                        <ul>
                                                            <li><a href="/" className="hs_tabs_btn">Vastushastra</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="hs_slider_right_tabs_wrapper hs_slider_right_tabs_wrapper2 right_tab" data-animation="animated bounceInLeft hs_slider_tab_three">
                                                    <div className="hs_slider_tabs_icon_wrapper">
                                                        <i className="flaticon-coins"></i>
                                                    </div>
                                                    <div className="hs_slider_tabs_icon_cont_wrapper">
                                                        <ul>
                                                            <li><a href="/" className="hs_tabs_btn">Numerology</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
											<div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 col-lg-offset-2">
												<div className="slider_bottom_tab">
													<div className="row">
														{/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
															<div className="hs_slider_right_tabs_wrapper hs_slider_right_tabs_wrapper2" data-animation="animated bounceInRight hs_slider_tab_four">
																<div className="hs_slider_tabs_icon_wrapper">
																	<i className="flaticon-shapes"></i>
																</div>
																<div className="hs_slider_tabs_icon_cont_wrapper">
																	<ul>
																		<li><a href="/" className="hs_tabs_btn">And More</a></li>
																	</ul>
																</div>
															</div>
														</div> */}
														{/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
															<div className="hs_slider_right_tabs_wrapper hs_slider_right_tabs_wrapper2" data-animation="animated bounceInLeft hs_slider_tab_fifth">
																<div className="hs_slider_tabs_icon_wrapper">
																	<i className="flaticon-coins"></i>
																</div>
																<div className="hs_slider_tabs_icon_cont_wrapper">
																	<ul>
																		<li><a href="/" className="hs_tabs_btn">Numerology</a></li>
																	</ul>
																</div>
															</div>
														</div> */}
													</div>
												</div>
											</div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className=" carousel-captions caption-2">
                        <div className="container">
                            <div className="row">
                                 <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="content">
                                        <h1 data-animation="animated bounceInLeft">Welcome To</h1>
                                        <h2 data-animation="animated zoomInDown">the <span>Alchemy</span> Center</h2>
                                        <p data-animation="animated bounceInUp">Welcome to Alchemy Center, where the mystical world of ancient practices meets modern guidance.<br/> I'm Vedika Bakawat, the founder of Alchemy Center, and I'd like to share my journey with you.</p>
                                        <div className="hs_effect_btn">
                                            <ul>
                                                <li data-animation="animated flipInX"><Link to="/about" className="hs_btn_hover">Read more</Link></li>
                                            </ul>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 hidden-xs">
                                    <div className="content_tabs">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="hs_slider_right_tabs_wrapper hs_slider_right_tabs_wrapper2" data-animation="animated bounceInRight hs_slider_tab_tow">
                                                    <div className="hs_slider_tabs_icon_wrapper">
                                                        <i className="flaticon-home"></i>
                                                    </div>
                                                    <div className="hs_slider_tabs_icon_cont_wrapper">
                                                        <ul>
                                                            <li><a href="/" className="hs_tabs_btn">Vasthusastra</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="hs_slider_right_tabs_wrapper hs_slider_right_tabs_wrapper2 right_tab" data-animation="animated bounceInLeft hs_slider_tab_three">
                                                    <div className="hs_slider_tabs_icon_wrapper">
                                                        <i className="flaticon-coins"></i>
                                                    </div>
                                                    <div className="hs_slider_tabs_icon_cont_wrapper">
                                                        <ul>
                                                            <li><a href="/" className="hs_tabs_btn">Numerology</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="carousel-captions caption-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="content">
                                        <h1 data-animation="animated bounceInLeft">Welcome To</h1>
                                        <h2 data-animation="animated zoomInDown">the <span>Alchemy</span> Center</h2>
                                        <p data-animation="animated bounceInUp">Welcome to Alchemy Center, where the mystical world of ancient practices meets modern guidance.<br/> I'm Vedika Bakawat, the founder of Alchemy Center, and I'd like to share my journey with you.</p>
                                        <div className="hs_effect_btn">
                                            <ul>
                                                <li data-animation="animated flipInX"><Link to="/about" className="hs_btn_hover">Read more</Link></li>
                                            </ul>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 hidden-xs">
                                    <div className="content_tabs">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="hs_slider_right_tabs_wrapper hs_slider_right_tabs_wrapper2" data-animation="animated bounceInRight hs_slider_tab_tow">
                                                    <div className="hs_slider_tabs_icon_wrapper">
                                                        <i className="flaticon-home"></i>
                                                    </div>
                                                    <div className="hs_slider_tabs_icon_cont_wrapper">
                                                        <ul>
                                                            <li><a href="/" className="hs_tabs_btn">Vasthusastra</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="hs_slider_right_tabs_wrapper hs_slider_right_tabs_wrapper2 right_tab" data-animation="animated bounceInLeft hs_slider_tab_three">
                                                    <div className="hs_slider_tabs_icon_wrapper">
                                                        <i className="flaticon-coins"></i>
                                                    </div>
                                                    <div className="hs_slider_tabs_icon_cont_wrapper">
                                                        <ul>
                                                            <li><a href="/" className="hs_tabs_btn">Numerology</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-nevigation">
                    <a className="prev" href="#carousel-example-generic" role="button" data-slide="prev">
						<i className="fa fa-sign-out"></i>
					</a>
                    <a className="next" href="#carousel-example-generic" role="button" data-slide="next">
						<i className="fa fa-sign-in"></i>
					</a>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Slider
